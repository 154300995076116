import classNames from 'classnames';
import React, { useMemo } from 'react';

import { Space, Typography } from '@sravni/react-design-system';

import * as Icons from '../Icons/Course';

import styles from './currencies.module.scss';

enum CurrencyDirection {
  UP = 'up',
  DOWN = 'down',
}

type TCurrencyItem = {
  link?: string;
  code: string;
  name?: string;
};

interface ICurrencyItemProps {
  currency: {
    dateFrom: any;
    dateTo: any;
  };
  item: TCurrencyItem;
}

const getIcon = (type: CurrencyDirection): JSX.Element => {
  switch (type) {
    case CurrencyDirection.UP:
      return <Icons.Up />;

    default:
      return <Icons.Down />;
  }
};

const getCurrencyString = (number: number): string =>
  number >= 1000
    ? String(Math.round(number)).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
    : number.toFixed(2).replace('.', ',');

const CurrencyItem: React.FC<ICurrencyItemProps> = React.memo(({ currency, item }) => {
  const curDir: CurrencyDirection = useMemo(() => {
    return currency?.dateTo?.price > currency?.dateFrom?.price ? CurrencyDirection.UP : CurrencyDirection.DOWN;
  }, [currency]);

  const diff: number = useMemo(() => Math.abs(currency?.dateTo?.price - currency?.dateFrom?.price), [currency]);

  if (!(currency && currency.dateFrom && currency.dateTo)) {
    return null;
  }

  return (
    <Typography.Link href={item.link} className={styles.currencyItem}>
      <Typography.Text className={classNames('h-color-D60', 'h-mr-4')}>{item.code}</Typography.Text>
      <Space align="center">
        <Typography.Text className={styles.currencyItem__course}>
          {getCurrencyString(currency.dateTo.price)}
        </Typography.Text>
        <Typography.Text className={classNames(styles.currencyItem__diff, styles[`currencyItem__diff--${curDir}`])}>
          {getCurrencyString(diff)}
        </Typography.Text>
        {getIcon(curDir)}
      </Space>
    </Typography.Link>
  );
});

export default CurrencyItem;
